import 'isomorphic-fetch';
import FormData from 'form-data';

const gquiz = (params, callback) => {
  // destructure params object
  const {
    score, quizId, username, email,
  } = params;
  // create new FormData object
  const data = new FormData();
  // populate the FormData
  data.append('score', score);
  data.append('quiz_id', quizId);
  data.append('option', '0');
  data.append('contact', '1');
  data.append('username', username);
  data.append('email', email);
  data.append('answers', '{}');
  // post off to gquiz
  fetch('https://gquiz.gamer-network.net/score', {
    method: 'POST',
    body: data,
  })
    .then(response => callback({
      err: null,
      res: response,
    }))
    // eslint-disable-next-line no-console
    .catch(e => callback({
      err: e,
      res: null,
    }));
};

export default gquiz;
