import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/seo';

import Wrapper from '../components/shared/wrapper';
import JoinWrapper from '../components/shared/JoinWrapper';
import EmailSubmit from '../components/shared/EmailSubmit';
import StyledSection from '../components/shared/section';
import ParallaxSection from '../components/shared/ParallaxSection';
import Footer from '../components/shared/Footer';

const HeroSection = styled(StyledSection)`
  position: relative;
  padding: 5rem 0 6rem 0;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0; left: 0;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -48px 40px 20px rgba(0,0,0,0.95);
    pointer-events: none;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
    
  @media only screen and (max-width: 1024px) {
    padding: 1rem 0;
    padding-bottom: 0;
    background: #000;
  }

  .main-bg {
    position: absolute !important;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.1);

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

const JoinArekkz = ({ data }) => (
  <Layout>
    <SEO page="Join Arekkz" />
    <HeroSection>
      <Img className="main-bg" fluid={data.mainBG.childImageSharp.fluid} />
      <Wrapper>
        <JoinWrapper team="Outside Xbox">
          <EmailSubmit
            signupText="Join Team Outside Xbox Now!"
            subText="Xbox Only, UK Residents"
            quizId={50}
          >
            <Img className="form-logo" fluid={data.oxboxLogo.childImageSharp.fluid} />
          </EmailSubmit>
        </JoinWrapper>
      </Wrapper>
    </HeroSection>
    <ParallaxSection background={data.parallaxBG.childImageSharp.fluid}>
      <span><h2>History</h2></span>
      <h2>Will</h2>
      <span><h2>Remember</h2></span>
      <a href="http://ubi.li/zkqv5" target="_blank" rel="noopener noreferrer">
        <Img className="parallax-cta" fluid={data.parallaxButton.childImageSharp.fluid} />
      </a>
    </ParallaxSection>
    <Footer />
  </Layout>
);

JoinArekkz.propTypes = {
  data: PropTypes.shape({}).isRequired,
};


export default JoinArekkz;

export const pageQuery = graphql`
  query {
    parallaxBG: file(relativePath: {eq: "quote_panel.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
    mainBG: file(relativePath: {eq: "keyart_bg.png"}) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
    arrekzLogo: file(relativePath: {eq: "arreks_logo_form.png"}) {
      childImageSharp {
        fluid(maxWidth: 525) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
          tracedSVG
        }
      }
    }
    oxboxLogo: file(relativePath: {eq: "oxbox_logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 562) {
          src
          sizes
          srcSet
          srcSetWebp
          aspectRatio
        }
      }
    }
    parallaxButton: file(relativePath: {eq: "CTA.png"}) {
      childImageSharp {
        fluid(maxWidth: 439) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
          tracedSVG
        }
      }
    }
  }
`;
