import styled from 'styled-components';

const StyledJoinWrapper = styled.div`
  position: relative;
  width: 100%; 
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 2px 12px 1px rgba(0,0,0,0.5);
  z-index: 1;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 3rem 0;
    margin-bottom: 0;
    transform: translateY(4px);
  }

  .join-wrapper-signup {
    flex-basis: 30%;
    background: #fff;
    padding-top: 1rem;

    @media only screen and (max-width: 768px) {
      padding-top: 0;
      transform: translateY(-2px);
    }

    .form-logo {
      width: 80%;
      max-width: 525px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    .email-submit-title {
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: #ff7224;
      font-weight: 400;
    }

    h5 {
      text-align: center;
      margin-bottom: 2rem;
      font-weight: 400;
    }
  }
`;

export default StyledJoinWrapper;
