import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import gquiz from './gquiz';
import TermsOpen from '../TermsOpen';

import StyledEmailSubmit from './style';

class EmailSubmit extends Component {
  state = {
    username: '',
    email: '',
    termsAgreed: false,
    submitted: false,
  }

  handleChange = (e) => {
    const targetValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const targetState = e.target.id;
    this.setState({
      [targetState]: targetValue,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { username, email } = this.state;
    const { quizId } = this.props;
    gquiz({
      quizId,
      score: 0,
      username,
      email,
    }, (res) => {
      if (!res.err) {
        this.setState({
          submitted: true,
        });
      } else {
        this.setState({
          submitted: true,
        });
      }
    });
  }

  render() {
    const {
      username,
      email,
      termsAgreed,
      submitted,
    } = this.state;
    const { signupText, submitText, subText } = this.props;
    const { children } = this.props;
    return (
      <StyledEmailSubmit>
        <Fragment>
          {children}
          <h4 className="email-submit-title">{signupText}</h4>
          <h5>{subText}</h5>
          <form className="email-submit-form" onSubmit={this.onSubmit}>
            <label className="username-wrapper" htmlFor="username">
              <input type="text" id="username" onChange={this.handleChange} value={username} placeholder="Name" required />
            </label>
            <label className="email-wrapper" htmlFor="email">
              <input type="email" id="email" onChange={this.handleChange} value={email} placeholder="Email" required />
            </label>
            {submitted === false ? (
              <Fragment>
                <label className="terms-wrapper" htmlFor="termsAgreed">
                  I agree to the
                  {' '}
                  <TermsOpen />
                  <input type="checkbox" id="termsAgreed" checked={termsAgreed} onChange={this.handleChange} required />
                </label>
                <button className="submit-btn" type="submit" value="Submit">Sign Up</button>
              </Fragment>
            ) : (
              <h3 className="success-text">{submitText}</h3>
            )}
          </form>
        </Fragment>
      </StyledEmailSubmit>
    );
  }
}

EmailSubmit.propTypes = {
  children: PropTypes.shape({}),
  quizId: PropTypes.number.isRequired,
  signupText: PropTypes.string,
  subText: PropTypes.string.isRequired,
  submitText: PropTypes.string,
};

EmailSubmit.defaultProps = {
  children: null,
  signupText: 'Sign Up Here',
  submitText: 'Thank You For Your Submission!',
};

export default EmailSubmit;
