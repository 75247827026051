import React from 'react';
import PropTypes from 'prop-types';

import StyledJoinWrapper from './style';

import SignupInstructions from '../SignupInstructions';

const JoinWrapper = ({ hero, team, children }) => (
  <StyledJoinWrapper hero={hero}>
    <SignupInstructions team={team} />
    <div className="join-wrapper-signup">
      {children}
    </div>
  </StyledJoinWrapper>
);

JoinWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.shape({}),
  team: PropTypes.string.isRequired,
};

JoinWrapper.defaultProps = {
  hero: {},
};

export default JoinWrapper;

// test
